:root {
  --color-dark: #111;
  --color-light: #dadada;
  --color-primary: rgb(52, 152, 219);
}

.App {
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  padding: 10px;
}

h1 {
  font-size: 2.5rem;
  color: var(--color-primary);
}

h2 {
  font-size: 1.5rem;
}

.App-picture {
  height: 17rem;
  width: 17rem;
  border-radius: 17rem;
  border-color: var(--color-primary);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: #282c34;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  background-color: white;
}

.App-main {
  background-color: #eee;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3rem;
}

.App-main-instructions-item {
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-gap: 0.5rem;
  margin: 1rem 2rem;
  max-width: 30rem;
}

.App-main p {
  max-width: 30rem;
  margin: 2rem;
}

a {
  color: var(--color-primary)
}

.App-calendar-link {
  margin-top: 2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-footer {
  display: flex;
  min-height: 5rem;
  align-items: center;
  justify-content: center;
}



/* body {
  margin: 20px;
  background: hsl(70, 31%, 85%);
  text-align: center;
} */

.balloons-container {
  height: 0px;
  text-align: center;
}

.balloon {
  display: inline-block;
  width: 6rem;
  height: 7rem;
  background: #088DEE;
  border-radius: 80%;
  position: relative;
  box-shadow: inset -10px -10px 0 rgba(0, 0, 0, 0.07);
  margin: 20px 10px;
  transition: transform 0.5s ease;
  z-index: 10;
  animation: balloons 11s linear forwards;
  transform-origin: bottom center;
}

@keyframes balloons {
  0% {
    transform: translateY(0) rotate(0);
  }

  25% {
    transform: translateY(-500px) rotate(4deg);
  }

  50% {
    transform: translateY(-1000px) rotate(-4deg);
  }

  75% {
    transform: translateY(-1500px) rotate(4deg);
  }

  100% {
    transform: translateY(-2000px) rotate(-4deg);
  }
}

/*
1 #088DEE
2 #FEEA6B
3 #F74F49
4 #ADCF34
5 #9DDDF2

*/

.balloon:before {
  content: "▲";
  font-size: 20px;
  color: #00489D;
  display: block;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: -12px;
  z-index: -100;
}

.balloon:after {
  display: inline-block;
  top: 7rem;
  position: absolute;
  height: 250px;
  width: 1px;
  margin: 0 auto;
  content: "";
  background: rgba(0, 0, 0, 0.2);
}

.balloon:nth-child(2) {
  background: #FEEA6B;
  animation-duration: 9.5s;
}

.balloon:nth-child(2):before {
  color: #D7B001;
}

.balloon:nth-child(3) {
  background: #F74F49;
  animation-duration: 8s;
}

.balloon:nth-child(3):before {
  color: #9E110D;
}

.balloon:nth-child(4) {
  background: #ADCF34;
  animation-duration: 10s;
}

.balloon:nth-child(4):before {
  color: #9BC700;
}

.balloon:nth-child(5) {
  background: #9DDDF2;
  animation-duration: 9.25s;
}

.balloon:nth-child(5):before {
  color: #27AAC9;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1f2023;
    color: #dadada;
  }

  .App-header {
    color: var(--color-light);
    background-color: var(--color-dark);
  }

  .App-main {
    color: var(--color-light);
    background-color: #555;
  }
}

